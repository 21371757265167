import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts'
import { colors } from '../components/theme'
import PageWrapper from '../components/PageWrapper'
import MetaHeader from '../components/MetaHeader'
import {
  Hero as BlogHero,
  FeaturedPosts,
  PaginatedPosts,
} from '../components/blog'
import PageIntro from '../components/PageIntro'

const Blog = ({ data }) => {
  const { edges: posts } = data.allContentfulPost
  const firstPost = posts[0]
  const bigPosts = posts.slice(1, 3)
  const restOfPosts = posts.slice(3)
  const {
    contentfulPageHeader: {
      heading,
      image,
      metaTitle,
      metaDescription,
      metaKeywords,
      metaSiteUrl,
      metaTwitter,
    },
  } = data

  return (
    <Layout>
      <MetaHeader
        title={metaTitle || heading}
        image={image?.file?.url}
        siteUrl={metaSiteUrl}
        description={metaDescription?.metaDescription}
        keywords={metaKeywords?.metaKeywords}
        twitter={metaTwitter}
      />
      <PageWrapper>
        <PageIntro title="Blog" />
        <BlogHero {...firstPost} background={colors.background} />
        <FeaturedPosts posts={bigPosts} />
        <PaginatedPosts posts={restOfPosts} />
      </PageWrapper>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query BlogPageQuery {
    allContentfulPost(sort: { fields: [postedAt], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          heroImage {
            title
            fluid {
              src
            }
          }
          postedAt(formatString: "MMMM Do, YYYY")
          category {
            id
            title
          }
        }
      }
    }
    contentfulPageHeader(id: { eq: "f542d36e-2b62-50f5-9d18-7f6a2292d18f" }) {
      id
      heading
      image {
        file {
          url
        }
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      metaKeywords {
        metaKeywords
      }
      metaSiteUrl
      metaTwitter
    }
  }
`
