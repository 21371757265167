import React from 'react'
import styled from 'styled-components'

import Date from './Date'
import HeroImage from './HeroImage'
import PostLink from './PostLink'

const Container = styled(PostLink)`
  position: relative;
  margin: 40px 0 60px;
  display: inline-flex;
`

const Inner = styled.div`
  position: absolute;
  bottom: 0;
  left: 20px;
  background-color: ${props => props.background};
  padding: 40px;
  max-width: 75%;
  transform: translateY(20px);

  h2 {
    padding: 0;
    margin: 0;
    font-size: 2.25rem;
    line-height: 1;
  }
`
const MainFeaturedImage = styled(HeroImage)`
  height: 20rem;
  object-fit: cover;
`

const Hero = ({
  to,
  background = 'transparent',
  node: { title, slug, heroImage, postedAt },
}) => (
  <Container to={`/blog/${slug}`}>
    {heroImage && (
      <MainFeaturedImage src={heroImage.fluid.src} alt={heroImage.title} />
    )}
    <Inner background={background}>
      <h2>{title}</h2>
      <Date>{postedAt}</Date>
    </Inner>
  </Container>
)

export default Hero
