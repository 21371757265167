import React from 'react'
import styled from 'styled-components'

import { colors, vars, fonts } from '../theme'

import { Date, HeroImage } from '.'
import PostLink from './PostLink'
import PostTitle from './PostTitle'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  width: 100%;
  margin-top: 2rem;

  @media (min-width: ${vars.screenMedium}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Title = styled(PostTitle)`
  font-size: 1.2rem;
  line-height: 1.2;
  margin-top: 0;
`

const Category = styled.div`
  font-family: ${fonts.heading};
  display: inline-flex;
  margin-right: 20px;
  padding: 3px 7px;
  background: ${colors.primaryLight};
  color: ${colors.secondaryDark};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.625rem;
  letter-spacing: 0.5px;
  border-radius: 3px;
`

const CategoryWrapper = styled.div`
  flex-grow: 1;
`

const CategoryBlock = ({ category }) => (
  <CategoryWrapper>
    {category && category.map(c => <Category key={c.id}>{c.title}</Category>)}
  </CategoryWrapper>
)

const CARD_PADDING = '0.75rem'

const PostContainer = styled(PostLink)`
  height: 160px;
  display: flex;
  padding: ${CARD_PADDING};
  background-color: ${colors.white};
  border: 1px solid ${colors.lighterGrey};
  transition: none;
`
const PostText = styled.div`
  display: flex;
  flex-direction: column;
`
const PostImage = styled(HeroImage)`
  margin: -${CARD_PADDING};
  margin-right: ${CARD_PADDING};
  max-width: 10rem;
`

const Posts = ({ posts = [] }) =>
  posts.map(({ node: { id, heroImage, title, postedAt, category, slug } }) => (
    <PostContainer key={id} to={`/blog/${slug}`}>
      {heroImage && (
        <PostImage src={heroImage.fluid.src} alt={heroImage.title} />
      )}

      <PostText>
        <Title>{title}</Title>
        <CategoryBlock category={category} />
        <Date>{postedAt}</Date>
      </PostText>
    </PostContainer>
  ))

const PaginatedPosts = ({ posts }) => (
  <Container>
    <Posts posts={posts} />
  </Container>
)

export default PaginatedPosts
