import styled from 'styled-components'
import { fonts, colors } from '../theme'

const PostTitle = styled.div`
  font-family: ${fonts.heading};
  color: ${colors.primaryText};
  font-weight: 600;
  margin-top: 10px;
  align-items: flex-end;
`
export default PostTitle
