import React from 'react'
import styled from 'styled-components'

import { colors, vars } from '../theme'
import { Date, HeroImage, PostLink } from '.'
import PostTitle from './PostTitle'

import image from '../../images/logos/ff-logo-square.png'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1.5rem;
  width: 100%;
  margin-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid ${colors.borderLight};

  @media (min-width: ${vars.screenSmall}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Title = styled(PostTitle)`
  font-size: 1.8rem;
  line-height: 1;
`
const FeaturedImage = styled(HeroImage)`
  margin-top: 1rem;
  height: 10rem;
  object-fit: contain;
`

const PostText = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`

const Posts = ({ posts = [] }) =>
  posts.map(({ node }) => (
    <PostLink key={node.id} to={`/blog/${node.slug}`}>
      {node.heroImage ? (
        <FeaturedImage
          src={node.heroImage.fluid.src}
          alt={node.heroImage.title}
        />
      ) : (
        <FeaturedImage src={image} alt="Female Funders logo" />
      )}
      <PostText>
        <Title>{node.title}</Title>
        <Date>{node.postedAt}</Date>
      </PostText>
    </PostLink>
  ))

const FeaturedPosts = ({ posts }) => (
  <Container>
    <Posts posts={posts} />
  </Container>
)

export default FeaturedPosts
