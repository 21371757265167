import styled from 'styled-components'
import { transparentize } from 'polished'
import Link from '../common/Link'
import { colors } from '../theme'

const PostLink = styled(Link)`
  text-decoration: none;
  margin: 0;
  background-color: ${colors.white};
  border: 1px solid ${colors.lighterGrey};

  &:hover,
  &:focus {
    background-color: ${transparentize(0.92, colors.primary)};
  }
`
export default PostLink
